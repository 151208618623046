/* eslint-disable @typescript-eslint/no-explicit-any */
import log from "loglevel";
import { ExternalToast, toast } from "sonner";

// Définir les niveaux de journalisation
log.setDefaultLevel(
  process.env.NODE_ENV === "development" ? "debug" : "silent"
);

// Fonction pour obtenir l'heure actuelle formatée
const getTime = () => {
  const now = new Date();
  return now.toISOString().replace("T", " ").replace("Z", "");
};

// Fonction pour formater les messages de log avec des styles CSS
const formatLog = (level: string, message: string, color: string) => {
  return [
    `%c[${getTime()}] ${level.toUpperCase()}: ${message}`,
    `color: ${color}; font-weight: bold`,
  ];
};

// Configurer les méthodes de log avec des couleurs
export const cl_logger = {
  debug: (
    config:
      | {
          message: string;
          toats_message?: string | boolean;
        }
      | string,
    ...args: any[]
  ) => {
    if (typeof config === "string")
      return log.debug(...formatLog("debug", config, "#3399FF"), ...args);
    else if (
      config.toats_message !== undefined &&
      typeof config.toats_message === "boolean"
    ) {
      toast.info(config.message);
    } else if (config.toats_message !== undefined) {
      toast.info(config.toats_message);
    }
    log.debug(...formatLog("debug", config.message, "#3399FF"), ...args);
  },
  info: (
    config:
      | {
          message: string;
          toats_message?: string | boolean;
        }
      | string,
    ...args: any[]
  ) => {
    if (typeof config === "string")
      return log.info(...formatLog("info", config, "#33CC33"), ...args);
    else if (
      config.toats_message !== undefined &&
      typeof config.toats_message === "boolean"
    ) {
      toast.info(config.message);
    } else if (config.toats_message !== undefined) {
      toast.info(config.toats_message);
    }
    log.info(...formatLog("info", config.message, "#33CC33"), ...args);
  },
  error: (
    config:
      | {
          message: string;
          toats_message?: string | boolean;
        }
      | string,
    ...args: any[]
  ) => {
    if (typeof config === "string")
      return log.error(...formatLog("debug", config, "#FF0000"), ...args);
    else if (
      config.toats_message !== undefined &&
      typeof config.toats_message === "boolean"
    ) {
      toast.error(config.message);
    } else if (config.toats_message !== undefined) {
      toast.error(config.toats_message);
    }
    log.error(...formatLog("error", config.message, "#FF0000"), ...args);
  },
  raise: (
    config: {
      message: string;
      toats_message?: string | boolean;
      e: any;
    },
    ...args: any[]
  ) => {
    if (
      config.toats_message !== undefined &&
      typeof config.toats_message === "boolean"
    ) {
      toast.error(config.message);
    } else if (config.toats_message !== undefined) {
      toast.error(config.toats_message);
    }
    cl_logger.error(
      {
        message: config.message,
      },
      ...args
    );
    cl_logger.debug(config.e);
  },
  warn: (
    config:
      | {
          message: string;
          toats_message?: string | boolean;
        }
      | string,
    ...args: any[]
  ) => {
    if (typeof config === "string")
      return log.info(...formatLog("warn", config, "#FFA500"), ...args);
    else if (
      config.toats_message !== undefined &&
      typeof config.toats_message === "boolean"
    ) {
      toast.warning(config.message);
    } else if (config.toats_message !== undefined) {
      toast.warning(config.toats_message);
    }
    log.info(...formatLog("warn", config.message, "#FFA500"), ...args);
  },
  success: (
    config:
      | {
          message: string;
          toats_message?: string | boolean;
          toats_config?: ExternalToast;
        }
      | string,
    ...args: any[]
  ) => {
    if (typeof config === "string")
      return log.info(...formatLog("success", config, "#33CC33"), ...args);
    if (
      config.toats_message === undefined ||
      typeof config.toats_message === "boolean"
    ) {
      toast.success(config.message);
    } else {
      toast.success(config.toats_message, config.toats_config);
    }
    log.info(...formatLog("success", config.message, "#33CC33"), ...args);
  },
};
