/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { cl_logger } from "@/lib/front-log";
import { SafeActionResult } from "next-safe-action";
import { Schema } from "zod";

// Typing for Validation Errors
interface ValidationErrors {
  _errors?: string[];
  name?: {
    _errors?: string[];
  };
}

export default function useFncAction<
  ServerError,
  S extends Schema | undefined,
  BAS extends readonly Schema[],
  CVE,
  CBAVE,
  Data,
  Params
>({
  fnc,
  valideStatus,
  onServerError,
  onError,
  onSucess,
  onValidationError,
  toastOnError,
  canRedirect = false,
}: {
  fnc: (
    params: Params
  ) => Promise<
    SafeActionResult<ServerError, S, BAS, CVE, CBAVE, Data> | undefined
  >;
  valideStatus: number | number[];
  onSucess: (data: Data) => void;
  onError?: (
    serverError?:
      | {
          status: number;
          message: string;
        }
      | ({
          status: number;
          message: string;
        } & ServerError)
      | Data
      | undefined
  ) => void;
  onServerError?: (
    serverError?:
      | string
      | {
          status: number;
          message: string;
        }
      | undefined
  ) => void;
  onValidationError?: (validationErrors?: ValidationErrors) => void;
  toastOnError?: boolean;
  canRedirect?: boolean;
}) {
  const callToAction = useCallback(
    async (params: Params) => {
      const res:
        | SafeActionResult<ServerError, S, BAS, CVE, CBAVE, Data>
        | undefined = await fnc(params);
      if (canRedirect) {
        return;
      }
      if (res === undefined) {
        cl_logger.warn({
          message: `No response from server from ${fnc}`,
          toats_message: false,
        });
        return;
      } else if (res?.validationErrors) {
        if (onValidationError) {
          onValidationError(res.validationErrors as ValidationErrors);
        }
        cl_logger.error({
          message: "Validation error",
          toats_message: toastOnError,
        });
        return res;
      } else if (res?.serverError) {
        if (onServerError) {
          onServerError((res as any).serverError);
        }
        cl_logger.error({
          message: "Server error",
          toats_message: toastOnError,
        });
        return;
      } else if (
        !res?.data ||
        (res.data as any).status !== valideStatus ||
        (Array.isArray(valideStatus) &&
          !valideStatus.includes((res.data as any).status))
      ) {
        if (onError) {
          onError(res?.data);
        }
        cl_logger.error({
          message: "Server error",
          toats_message: toastOnError,
        });
        return;
      }
      if (onSucess) {
        onSucess(res.data);
      }
    },
    [
      fnc,
      onError,
      onSucess,
      onValidationError,
      onServerError,
      valideStatus,
      toastOnError,
    ]
  );

  return [callToAction];
}
